<template>
  <keep-alive>
    <component
      :is="current"
      v-bind="params"
      @edit="onEdit"
      @new="onNew"
      @close="backToList"
    />
  </keep-alive>
</template>

<script>
import orderList from '@/views/dashboard/components/orders/orderList';
import orderForm from '@/views/dashboard/components/orders/orderForm';

export default {
  components: {},
  data: function() {
    return {
      current: orderList,
      params: {}
    };
  },
  computed: {},
  watch: {},
  methods: {
    onNew() {
      this.current = orderForm;
      this.params = {
        value: {
          status: '',
          when_created: new Date(),
          id: '',
          client: '',
          order_no: '',
          client_order_no: '',
          due_date: null,
          item_no: '',
          description: '',
          die_cut_id: '',
          die_cut_ro: '',
          new_die_cut: false,
          duplex: false,
          braille_enabled: false,
          braille_id: '',
          // braille: false,
          braille_ro: null,
          braille_text: '',
          new_braille: false,
          cardboard_thickness: '',
          varnish_free_area: false,
          varnish_plate_number: '',
          pms: false,
          quantity: 0,
          serialization: false,
          comments: '',
          lot_no: '',
          created_at: new Date(),
          updated_at: new Date()
        }
      };
    },
    onEdit(item) {
      this.current = orderForm;
      this.params = {
        value: item
      };
    },
    backToList() {
      this.current = orderList;
      this.params = {};
    }
  }
};
</script>

<style></style>
